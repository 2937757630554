<template>
  <PageLayout>
    <template #header>
      <h1 class="display-1 text-center mt-3">Créez votre compte</h1>
      <hr />
      <PageHeader>
        <Steps :model="steps" :readonly="false"></Steps>
      </PageHeader>
    </template>
    <template #default>
      <router-view
        v-slot="{ Component }"
        :formData="formObject"
        :roles="roles"
        @prevPage="prevPage($event)"
        @nextPage="nextPage($event)"
        @complete="complete"
        class="p-inputtext-sm"
      >
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </template>
    <template #footer>
      <div
        class="flex justify-content-center align-items-center h-100"
        style="height: 100%"
      >
        <span class="text-xs mr-1">Vous avez déjà un compte?</span>
        <router-link :to="{ name: 'Login' }">Se connecter</router-link>
      </div>
    </template>
  </PageLayout>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";

export default {
  setup() {
    const router = useRouter();
    const toast = useToast();
    const steps = ref([
      { label: "Informations", to: "/register/1" },
      { label: "Mot de passe", to: "/register/2" },
      { label: "Etablissement", to: "/register/3" },
      { label: "Confirmation", to: "/register/4" },
    ]);
    const formObject = ref({});
    const roles = ref([
      { name: "Employé", value: "user" },
      { name: "Direction", value: "moderator" },
      { name: "Contrôle de gestion", value: "admin" },
    ]);

    const nextPage = (event) => {
      for (let field in event.formData) {
        formObject.value[field] = event.formData[field];
      }
      router.push(steps.value[event.pageIndex + 1].to);
    };
    const prevPage = (event) => {
      router.push(steps.value[event.pageIndex - 1].to);
    };
    const complete = () => {
      toast.add({
        severity: "success",
        summary: "Order submitted",
        detail:
          "Dear, " +
          formObject.value.firstname +
          " " +
          formObject.value.lastname +
          " your order completed.",
      });
    };

    return { steps, formObject, nextPage, prevPage, complete, roles };
  },
};
</script>

<style lang="scss">
.p-steps-item {
  font-size: 0.75rem;
}
</style>
